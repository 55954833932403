<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="mb-2 text-right">
            <b-button variant="info" class="mr-2" v-b-modal.add-caregiverReport>
              <i class="fa fa-plus"></i>
            </b-button>

            <button
              type="button"
              class="mr-2 btn btn-primary"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>

          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="mt-4 mb-4 table-responsive">
              <table
                id="elderly_caregiverReports"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Elderly Name</th>
                    <th>Elderly Email</th>
                    <th>Elderly Phone</th>
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="caregiverReport in caregiverReports"
                    :key="caregiverReport.id"
                  >
                    <td>{{ caregiverReport.elderly_name }}</td>
                    <td>{{ caregiverReport.elderly_email }}</td>
                    <td>{{ caregiverReport.elderly_phone_number }}</td>
                    <td>
                      <span class="p-1 rounded-sm bg-dark">
                        {{
                          caregiverReport.user.firstname +
                          " " +
                          caregiverReport.user.lastname
                        }}
                      </span>
                    </td>
                    <td>{{ formatDate(caregiverReport.created_at) }}</td>
                    <td class="d-flex">
                      <b-button
                        variant="outline-success"
                        size="sm"
                        class="mr-3"
                        @click="showCaregiverReport(caregiverReport.id)"
                      >
                        <i class="fa fa-eye"></i>
                      </b-button>
                      <b-button
                        @click.prevent="showDelete(caregiverReport.id)"
                        variant="outline-danger"
                        size="sm"
                        class="mr-3"
                      >
                        <i class="fa fa-trash"></i>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Modal -->
    <b-modal
      id="add-caregiverReport"
      title="Caregiver Report To Elderly"
      size="lg"
      centered
    >
      <form>
        <div class="form-group">
          <label for="patientName">Elderly Name</label>
          <v-select
            v-model="selectedPatient"
            :options="patients"
            label="fullname"
            :filterable="false"
            @search="vselectSearch"
          >
            <template #no-options> type to search patients... </template>

            <template #list-header v-if="vselectSearchResults">
              <div
                class="p-3 bg-dark border-bottom d-flex justify-content-between"
              >
                <span class="small">
                  Total: {{ vselectSearchResults.total }}<br />
                  Pages: {{ vselectSearchResults.last_page }}<br />
                  Current Page: {{ vselectSearchResults.current_page }}
                </span>
                <div class="mb-2">
                  <b-btn
                    :disabled="!vselectSearchResults.prev_page_url"
                    @click="
                      vselectGoToPage(vselectSearchResults.current_page - 1)
                    "
                    variant="info"
                    class="mr-2"
                    size="sm"
                    squared
                  >
                    &laquo; Prev
                  </b-btn>
                  <b-btn
                    :disabled="!vselectSearchResults.next_page_url"
                    @click="
                      vselectGoToPage(vselectSearchResults.current_page + 1)
                    "
                    variant="success"
                    size="sm"
                    squared
                  >
                    Next &raquo;
                  </b-btn>
                </div>
              </div>
            </template>
          </v-select>
        </div>

        <div v-if="selectedPatient">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="
                    selectedPatient.firstname + ' ' + selectedPatient.lastname
                  "
                  readonly
                />
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Email </label>
                <input
                  type="email"
                  class="form-control"
                  v-model="selectedPatient.email"
                  readonly
                />
              </div>
            </div>

            <div class="col-12 col-md-12">
              <div class="form-group">
                <label>Phone Number </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedPatient.phone"
                  readonly
                />
              </div>
            </div>
            <div class="col-12 col-md-12">
              <div class="mt-4 form-group">
                <label for="exemplaryService">Content</label>
                <textarea
                  required
                  class="form-control"
                  id="caregiverreport-remark"
                  v-model="newCaregiverReport.remark"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </form>

      <template #modal-footer>
        <b-button
          size="sm"
          variant="danger"
          @click="closeAddCaregiverReportModal()"
        >
          Discard
        </b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="saveCaregiverReport()">
          <b-spinner small v-if="loadingNew"></b-spinner>
          <span v-else>Save</span>
        </b-button>
      </template>
    </b-modal>

    <!-- View Modal -->
    <b-modal
      id="view-caregiverReport"
      size="lg"
      centered
      ok-only
      title="Caregiver Report"
    >
      <b-table-simple v-if="caregiverReport" responsive>
        <b-tbody>
          <b-tr>
            <b-th>Elderly Name</b-th>
            <b-td>{{ caregiverReport.elderly_name }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Elderly Email</b-th>
            <b-td>{{ caregiverReport.elderly_email }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Elderly Phone</b-th>
            <b-td>{{ caregiverReport.elderly_phone_number }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Remark</b-th>
            <b-td>{{ caregiverReport.remark }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>

    <!-- Search Modal -->
    <div
      class="modal fade"
      id="searchModal"
      role="dialog"
      aria-labelledby="searchModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="searchModalLabel">Search Options</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="dateSearch">Date Search</label>
                  <select class="form-control" v-model="search" id="dateSearch">
                    <option value="all">All</option>
                    <option value="thisweek">This Week (From Monday)</option>
                    <option value="pastweek">Past Week (Last 7 days)</option>
                    <option value="lastmonth">Last Month</option>
                    <option value="thismonth">This Month</option>
                    <option value="thisyear">This Year</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>

                <div class="form-group" v-if="search == 'custom'">
                  <label>Select a range</label>
                  <div class="input-group">
                    <flat-pickr
                      v-model="date"
                      :config="config"
                      class="form-control"
                      placeholder="Select date"
                      @on-close="handlePicker()"
                      name="date"
                    >
                    </flat-pickr>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Discard
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="handleSearch()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete  -->
    <div
      class="modal fade"
      id="deleteModal"
      role="dialog"
      aria-labelledby="editContactModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="fa fa-close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p class="text-danger">
                    Are you sure you want to delete this caregiverReport Record?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">Cancel</button>

            <button
              @click.prevent="completeDelete()"
              class="text-white btn btn-danger"
              :disabled="loadingDelete"
            >
              <b-spinner small v-if="loadingDelete"></b-spinner>
              <span v-else>Confirm Delete <i class="fa fa-trash"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { http } from "@/utils/http";
import { gerohttp } from "@/utils/gerocarehttp";
import { endpoints, geroendpoints } from "@/utils/endpoints";
import { CaregiverReport } from "@/models/prospect.js";
import { DatePickerConfig } from "@/models/datepicker.js";
import vselectPaginationSearch from "@/mixins/v-select/pagination-search";

var moment = require("moment");

export default {
  name: "CaregiverReports",

  mixins: [vselectPaginationSearch],

  data() {
    return {
      loading: false,
      caregiverReports: [],
      caregiverReport: null,

      search: "thismonth",
      date: null,
      config: new DatePickerConfig(),
      customStart: "",
      customEnd: "",

      patients: [],
      selectedPatient: null,

      today: moment().toDate(),

      loadingNew: false,
      newCaregiverReport: new CaregiverReport(),

      loadingDelete: false,
      deleteAddElderlycaregiverReport: "",

      visits: [],
      questions: [],
      visit: null,
    };
  },

  watch: {
    selectedPatient() {
      this.visits = [];
      this.visit = null;
    },
  },

  mounted() {
    this.fetchCaregiverReportRecords();
  },

  methods: {
    vselectComponentSearch(loading, vm) {
      gerohttp
        .get(geroendpoints.FETCH_PATIENTS, {
          params: {
            page: vm.vselectSearchPage,
            "filter[full_name]": vm.vselectSearchTerm,
          },
        })
        .then((response) => {
          vm.vselectSearchResults = response;
          vm.patients = response.data.map((patient) => {
            patient.fullname = `${patient.firstname} ${
              patient.midname ? patient.midname : ""
            } ${patient.lastname}`;

            return patient;
          });
        })
        .catch((error) => {
          vm.$toast.error(error);
        })
        .finally(() => {
          loading(false);
        });
    },

    vselectComponentClearSelection() {
      this.patients = [];
    },

    initTable() {
      $("#elderly_caregiverReports").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
    },

    handleSearch() {
      if (
        this.search == "custom" &&
        (this.customStart == "" || this.customEnd == "")
      ) {
        this.$toast.error("Enter a valid date range");
      } else {
        this.fetchCaregiverReportRecords();
        $("#searchModal").modal("hide");
      }
    },

    handlePicker() {
      setTimeout(() => {
        if (this.date.length < 15) {
          this.$toast.error("Enter a valid date range");
        } else {
          var split = this.date.split(" ");
          this.customStart = split[0];
          this.customEnd = split[2];
        }
      }, 500);
    },

    fetchCaregiverReportRecords() {
      this.loading = true;
      http
        .get(endpoints.CAREGIVER_REPORTS, {
          params: {
            search: this.search,
            customStart: this.customStart,
            customEnd: this.customEnd,
          },
        })
        .then((response) => {
          this.loading = false;
          this.caregiverReports = response.data;
          setTimeout(() => {
            this.initTable();
          }, 500);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    showCaregiverReport(id) {
      http
        .get(endpoints.CAREGIVER_SHOW.replace(":id", id))
        .then((response) => {
          this.caregiverReport = response.data;
          this.$bvModal.show("view-caregiverReport");
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    saveCaregiverReport() {
      this.loadingNew = true;
      http
        .post(endpoints.SAVE_CAREGIVER_REPORT, {
          elderly_name: this.selectedPatient.fullname,
          elderly_email: this.selectedPatient.email,
          elderly_phone_number: this.selectedPatient.phone,
          remark: this.newCaregiverReport.remark,
        })
        .then((response) => {
          this.loadingNew = false;
          this.$toast.success(response?.message);
          this.newCaregiverReport = new CaregiverReport();
          this.closeAddcaregiverReportModal();
          this.fetchCaregiverReportRecords();
        })
        .catch((error) => {
          this.loadingNew = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    showDelete(x) {
      this.deleteAddElderlycaregiverReport = x;
      $("#deleteModal").modal("show");
    },

    completeDelete() {
      this.loadingDelete = true;
      http
        .delete(
          endpoints.DELETE_CAREGIVER_REPORT.replace(
            ":id",
            this.deleteAddElderlycaregiverReport
          )
        )
        .then((response) => {
          this.loadingDelete = false;
          $("#deleteModal").modal("hide");
          this.$toast.success(response.message);
          this.fetchCaregiverReportRecords();
        })
        .catch((error) => {
          this.loadingDelete = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    closeAddCaregiverReportModal() {
      this.selectedPatient = null;
      this.$bvModal.hide("add-caregiverReport");
    },
  },
};
</script>
